import React from 'react';
import ContentDiagram from 'organisms/contentdiagram/ContentDiagram';
import ContentLogoListing from 'organisms/contentlogolisting/ContentLogoListing';
import ContentSection from 'organisms/contentsection/ContentSection';
import FeatureBlockListing from 'organisms/featureblocklisting/FeatureBlockListing';
import Newsletter from 'organisms/newsletter/Newsletter';

let index = 0;

function renderComponents(component, type) {
    switch (type) {
    case 'content_diagram':
        index++;
        return (
            <ContentDiagram
                key={`${type}-${index}`}
                content={component?.primary?.cd_content?.raw}
                heading={component?.primary?.cd_heading?.raw?.[0]?.text}
                link={component?.primary?.cd_button_url}
                linkText={component?.primary?.cd_button_text?.raw?.[0]?.text}
            />
        );
    case 'content___logo_list':
        index++;
        return (
            <ContentLogoListing
                key={`${type}-${index}`}
                content={component?.primary?.cl_content?.raw}
                heading={component?.primary?.cl_heading?.raw?.[0]?.text}
                link={component?.primary?.cl_button_url}
                linkText={component?.primary?.cl_button_text?.raw?.[0]?.text}
                listing={component?.items}
            />
        );
    case 'content_section':
        index++;
        return (
            <ContentSection
                key={`${type}-${index}`}
                alignment={component?.primary?.cs_content_alignment}
                content={component?.primary?.cs_content?.raw}
                heading={component?.primary?.cs_heading?.raw?.[0]?.text}
                image={{
                    alt: component?.primary?.cs_background_image?.alt,
                    dimensions: {
                        height: component?.primary?.cs_background_image?.dimensions?.height,
                        width: component?.primary?.cs_background_image?.dimensions?.width,
                    },
                    url: component?.primary?.cs_background_image?.url,
                }}
                link={component?.primary?.cs_button_url}
                linkText={component?.primary?.cs_button_text?.raw?.[0]?.text}
            />
        );
    case 'timeline':
        index++;
        return (
            <FeatureBlockListing
                key={`${type}-${index}`}
                listing={component?.items}
            />
        );
    case 'newsletter':
        index++;
        return (
            <Newsletter
                key={`${type}-${index}`}
                content={component?.primary?.nl_description?.raw}
                description={component?.primary?.nl_form_description?.raw}
                formEmailHelperText={component?.primary?.nl_form_helper_text?.raw}
                heading={component?.primary?.nl_heading?.raw}
            />
        );
    default:
        return null;
    }
}

export { renderComponents };
