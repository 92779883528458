import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import FeatureBlockItem from 'molecules/featureblockitem/FeatureBlockItem';
import automatedAnimationData from '../../lottie/automated.json';
import intelligentAnimationData from '../../lottie/intelligent.json';
import massProducedAnimationData from '../../lottie/mass-produced.json';
import mechanisedAnimationData from '../../lottie/mechanised.json';

import './FeatureBlockListing.scss';

const defaultProps = {
    className: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function renderAnimation(name) {
    switch(name) {
        case 'Automated':
            return automatedAnimationData;
        case 'Intelligent':
            return intelligentAnimationData;
        case 'Mass Produced':
            return massProducedAnimationData;
        case 'Mechanised':
            return mechanisedAnimationData;
        default:
            return '';
    }
}

function FeatureBlockListing({
    className,
    listing,
}) {
    const [state, setState] = useState(() => {
        const initialAnimationsPlayed = {};
        listing.forEach((node, index) => {
            initialAnimationsPlayed[index] = false;
        });
        return {
            animationLength: listing.length,
            animationsPlayed: initialAnimationsPlayed,
        }
    });

    function handleAnimationComplete(id) {
        setState((previousState) => {
            return {
                ...previousState,
                animationsPlayed: {
                    ...previousState.animationsPlayed,
                    [id]: true,
                }
            }
        });

        if (id === state.animationLength - 1) {
            resetAnimationState();
        }
    }

    function resetAnimationState() {
        let i;

        for (i = 0; i < state.animationLength; i++) {
            setState((previousState) => {
                return {
                    ...previousState,
                    animationsPlayed: {
                        [i]: false,
                    }
                }
            });
        }
    }

    return (
        <>
            <div className={`o-feature-block-listing ${className}`}>
                <div className="l-grid-large l-grid-large--container">
                    <ol className="o-feature-block-listing__container">
                        {listing.map(({ fb_heading, fb_icon, fb_subheading }, index) => (
                            <InView key={`{${index}}-feature-block-item`} threshold={1} triggerOnce={false}>
                                {({ inView, ref, entry }) => {
                                    const heading = fb_heading?.raw?.[0]?.text;
                                    const icon = fb_icon;
                                    const subheading = fb_subheading?.raw?.[0]?.text;
                                    const isVisible = entry && inView;
                                    const playAnimation = (index === 0) ? isVisible && !state.animationsPlayed[index] : isVisible && !state.animationsPlayed[index] && state.animationsPlayed[index - 1]; // Only play the animation if the element is in view and the previous item has played

                                    return (
                                        <li className="o-feature-block-listing__item" ref={ref}>
                                            <FeatureBlockItem
                                                animationData={renderAnimation(icon)}
                                                heading={heading}
                                                id={index}
                                                onComplete={handleAnimationComplete}
                                                playAnimation={playAnimation}
                                                subheading={subheading}
                                            />
                                        </li>
                                    );
                                }}
                            </InView>
                        ))}
                    </ol>
                </div>
            </div>
        </>
    );
}

FeatureBlockListing.propTypes = propTypes;
FeatureBlockListing.defaultProps = defaultProps;

export default FeatureBlockListing;