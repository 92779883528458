import React from 'react';
import PropTypes from 'prop-types';
import { default as cx } from 'clsx';

import Button from 'atoms/button/Button';
import Content from 'atoms/content/Content';
import Image from 'atoms/image/Image';

import './ContentSection.scss';

const defaultProps = {
    alignment: 'Left',
    className: '',
    content: [],
    heading: '',
    image: {},
    link: {},
    linkText: '',
};

const propTypes = {
    alignment: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    heading: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.objectOf(PropTypes.number),
        url: PropTypes.string,
    }),
    link: PropTypes.object,
    linkText: PropTypes.string,
};

function ContentSection({
    alignment,
    className,
    content,
    heading,
    image,
    link,
    linkText,
}) {
    const alignmentClassName = `o-content-section--align-${alignment.toLowerCase()}`;
    const linkUrl = link;
    const imageAlt = (image.alt) ? image.alt : '';
    const imageHeight = image?.dimensions?.height;
    const imageUrl = image?.url;
    const imageWidth = image?.dimensions?.width;
    const imageClassName = (imageUrl) ? 'o-content-section--background-image' : '';
    const classNames = cx('o-content-section', alignmentClassName, imageClassName, className);

    return (
        <>
            <section className={classNames}>
                <div className="l-grid l-grid--container">
                    {heading && (
                        <div className="o-content-section__heading-container">
                            <h2 className="o-content-section__heading">{heading}</h2>
                        </div>
                    )}
                    {content && (
                        <div className="o-content-section__content-container">
                            <Content className="o-content-section__content" content={content} />
                        </div>
                    )}
                    {linkText && linkUrl && (
                        <div className="o-content-section__button-container">
                            <Button className="a-button--secondary o-content-section__button" to={linkUrl}>{linkText}</Button>
                        </div>
                    )}
                    {(imageUrl) && (
                        <div className="o-content-section__media-container">
                            <Image
                                className="o-content-section__image"
                                image={{
                                    alt: imageAlt,
                                    dimensions: {
                                        height: imageHeight,
                                        width: imageWidth,
                                    },
                                    url: imageUrl,
                                }}
                                size='xlarge'
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

ContentSection.propTypes = propTypes;
ContentSection.defaultProps = defaultProps;

export default ContentSection;
