import React from 'react';
import PropTypes from 'prop-types';
import Button from 'atoms/button/Button';
import Content from 'atoms/content/Content';
import LogoListing from 'organisms/logolisting/LogoListing';

import './ContentLogoListing.scss';

const defaultProps = {
    className: '',
    content: [],
    heading: '',
    link: {},
    linkText: '',
    listing: [],
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    heading: PropTypes.string,
    link: PropTypes.object,
    linkText: PropTypes.string,
    listing: PropTypes.arrayOf(PropTypes.object),
};

function ContentLogoListing({
    className,
    content,
    heading,
    link,
    linkText,
    listing,
}) {
    const linkUrl = link;
    const logoListing = [];
    {listing.map(({ cl_logo_image, cl_logo_link_url }) => {
        logoListing.push({
            ll_image: cl_logo_image,
            ll_url: cl_logo_link_url,
        });
    })}

    return (
        <>
            <div className={`o-content-logo-listing ${className}`}>
                {heading && (
                    <div className="l-grid l-grid--container">
                        {heading && (
                            <div className="o-content-logo-listing__heading-container">
                                <h2 className="o-content-logo-listing__heading">{heading}</h2>
                            </div>
                        )}
                    </div>
                )}
                {listing && (
                    <div className="l-grid-large l-grid-large--container">
                        <div className="o-content-logo-listing__logo-listing-container">
                            <LogoListing
                                className="o-content-logo-listing__logo-listing"
                                listing={logoListing}
                            />
                        </div>
                    </div>
                )}
                {(content || (linkText && linkUrl)) && (
                    <div className="l-grid l-grid--container">
                        {content && (
                            <div className="o-content-logo-listing__content-container">
                                <Content className="o-content-logo-listing__content" content={content} />
                            </div>
                        )}
                        {linkText && linkUrl && (
                            <div className="o-content-logo-listing__button-container">
                                <Button className="a-button--secondary o-content-logo-listing__button" to={linkUrl}>{linkText}</Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

ContentLogoListing.propTypes = propTypes;
ContentLogoListing.defaultProps = defaultProps;

export default ContentLogoListing;
