import React, { useState } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import validation from 'utility/validation';

import Button from 'atoms/button/Button';
import Content from 'atoms/content/Content';
import Message from 'atoms/message/Message';
import FormGroupInput from 'molecules/formgroupinput/FormGroupInput';

import './FormNewsletter.scss';

const defaultProps = {
    className: '',
    emailHelperText: [],
    emailLabel: "Email",
    emailPlaceholder: "Enter your email",
    id: "1",
    legend: 'Newsletter',
    submitButtonText: 'SUBSCRIBE',
};

const propTypes = {
    className: PropTypes.string,
    emailHelperText: PropTypes.arrayOf(PropTypes.object),
    emailLabel: PropTypes.string,
    emailPlaceholder: PropTypes.string,
    id: PropTypes.string,
    legend: PropTypes.string,
    submitButtonText: PropTypes.string,
};

function FormNewsletter({
    className,
    emailHelperText,
    emailLabel,
    emailPlaceholder,
    id,
    legend,
    submitButtonText,
}) {
    const [state, setState] = useState({
        disableSubmitButton: false,
        errors: {
            email: true,
        },
        errorClassName: '',
        message: {
            message: '',
            type: null,
        },
        values: {
            email: '',
        },
    });
    const formSubmittedSuccessfully = (state.message.type === 'success') ? true : false;

    function validateField(field) {
        let error;

        if (field.type === 'email') {
            error = validation.validateEmail(field.value) ? false : true;
        } else {
            error = field.checkValidity() ? false : true;
        }

        setState((previousState) => {
            return {
                ...previousState,
                errors: {
                    ...previousState.errors,
                    [field.name]: error,
                }
            }
        });
    }

    function handleInputChange(event) {
        event.persist();

        setState((previousState) => {
            return {
                ...previousState,
                values: {
                    ...previousState.values,
                    [event.target.name]: event.target.value,
                },
            }
        });

        validateField(event.target);
    }

    function resetErrorClassName() {
        setState((previousState) => {
            return {
                ...previousState,
                disableSubmitButton: false,
                errorClassName: '',
            }
        });
    }

    async function handleSubmit(event) {
        event.preventDefault();

        // Disable submit button whilst posting data
        setState((previousState) => {
            return {
                ...previousState,
                disableSubmitButton: true,
            }
        });

        const response = await addToMailchimp(state.values.email);

        setState((previousState) => {
            return {
                ...previousState,
                errorClassName: `has-${response.result}`,
                message: {
                    ...previousState.values,
                    message: response.msg,
                    type: response.result,
                },
            }
        });
    }

    return (
        <>
            <div className={`o-form-newsletter ${className} ${state.errorClassName}`} onAnimationEnd={resetErrorClassName}>
                <form className="o-form-newsletter__form" onSubmit={handleSubmit}>
                    <fieldset className="o-form-newsletter__fieldset">
                        <legend className="o-form-newsletter__legend">{legend}</legend>
                        {state.message.type && (
                            <Message
                                className={`a-message--${state.message.type} o-form-newsletter__message`}
                                compact={true}
                                html={`<p>${state.message.message}</p>`}
                                type={state.message.type}
                            />
                        )}
                        {!formSubmittedSuccessfully && (
                            <div className="o-form-newsletter__field-group">
                                <div className="o-form-newsletter__input-group">
                                    <FormGroupInput
                                        className="o-form-newsletter__input-field"
                                        id={`newsletter-email${id}`}
                                        label={emailLabel}
                                        name="email"
                                        onChange={handleInputChange}
                                        placeholder={emailPlaceholder}
                                        required={true}
                                        type="email"
                                    />
                                    {emailHelperText && (
                                        <Content
                                            className="o-form-newsletter__helper-text"
                                            content={emailHelperText}
                                        />
                                    )}
                                </div>
                                <Button className="a-button--secondary o-form-newsletter__submit-button" disabled={state.disableSubmitButton} type="submit">{submitButtonText}</Button>
                            </div>
                        )}
                    </fieldset>
                </form>
            </div>
        </>
    );
}

FormNewsletter.propTypes = propTypes;
FormNewsletter.defaultProps = defaultProps;

export default FormNewsletter;
