import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import Content from 'atoms/content/Content';
import FormNewsletter from 'organisms/formnewsletter/FormNewsletter';

import './Newsletter.scss';

const defaultProps = {
    className: '',
    content: [],
    description: [],
    heading: [],
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    description: PropTypes.arrayOf(PropTypes.object),
    formEmailHelperText: PropTypes.arrayOf(PropTypes.object),
    formEmailLabel: PropTypes.string,
    formEmailPlaceholder: PropTypes.string,
    formLegend: PropTypes.string,
    formSubmitButtonText: PropTypes.string,
    heading: PropTypes.PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.string,
};

function Newsletter({
    className,
    content,
    description,
    formEmailHelperText,
    formEmailLabel,
    formEmailPlaceholder,
    formLegend,
    formSubmitButtonText,
    heading,
    id,
}) {
    const [ref, inView, entry] = useInView({
        triggerOnce: true,
    });
    const isInview = (entry && inView) ? 'is-inview' : '';
    const legend = (heading?.[0].text) ? heading?.[0].text : formLegend;

    return (
        <>
            <div className={`o-newsletter ${className} ${isInview}`} ref={ref}>
                <div className="l-grid l-grid--container">
                    <div className="o-newsletter__container">
                        {heading && (
                            <h2 className="o-newsletter__heading">
                                <Content
                                    content={heading}
                                />
                            </h2>
                        )}
                        {description && (
                            <Content
                                className="o-newsletter__content"
                                content={description}
                            />
                        )}
                        <div className="o-newsletter__form-container">
                            {content && (
                                <Content
                                    className="o-newsletter__form-content"
                                    content={content}
                                />
                            )}
                            <FormNewsletter
                                className="o-newsletter__form"
                                emailHelperText={formEmailHelperText}
                                emailLabel={formEmailLabel}
                                emailPlaceholder={formEmailPlaceholder}
                                id={id}
                                legend={legend}
                                submitButtonText={formSubmitButtonText}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export default Newsletter;