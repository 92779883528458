import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { renderComponents } from 'utility/renderHomeComponents';
import Shell from 'layout/shell/Shell';
import Masthead from 'organisms/masthead/Masthead';
import Newsletter from 'organisms/newsletter/Newsletter';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function HomePage({
    data,
    location: {
        pathname,
    },
}) {
    const doc = data?.prismicHome?.data;
    const pageDescription = doc?.page_meta_description?.raw?.[0]?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.raw?.[0]?.text;
    const components = doc?.body;
    const mastheadBackgroundImageAltText = doc?.ma_background_image?.alt;
    const mastheadBackgroundImageHeight = doc?.ma_background_image?.dimensions?.height;
    const mastheadBackgroundImageUrl = doc?.ma_background_image?.url;
    const mastheadBackgroundImageWidth = doc?.ma_background_image?.dimensions?.width;
    const mastheadBackgroundVideoUrl = doc?.ma_background_video?.url;
    const mastheadDescription = doc?.ma_description?.raw?.[0]?.text;
    const mastheadHeading = doc?.ma_heading?.raw?.[0]?.text;
    const mastheadVideoEmbedId = doc?.ma_video_embed?.embed_url;
    const preloadAssets = [];

    if (mastheadBackgroundImageUrl) {
        preloadAssets.push(
            {
                as: 'image',
                crossorigin: '',
                href: mastheadBackgroundImageUrl,
                rel: 'preload',
            }
        );
    }

    if (mastheadBackgroundVideoUrl) {
        preloadAssets.push(
            {
                as: 'video',
                crossorigin: '',
                href: mastheadBackgroundVideoUrl,
                rel: 'preload',
            }
        );
    }

    return (
        <Shell
            pathname={pathname}
            preloadAssets={preloadAssets}
            seoDescription={pageDescription}
            seoImage={pageSocialImage}
            seoTitle={pageTitle}
        >
            <main className="l-grid__main" id="main-content">
                <Masthead
                    className="o-masthead--home"
                    description={mastheadDescription}
                    heading={mastheadHeading}
                    image={{
                        alt: mastheadBackgroundImageAltText,
                        dimensions: {
                            height: mastheadBackgroundImageHeight,
                            width: mastheadBackgroundImageWidth,
                        },
                        // url: mastheadBackgroundImageUrl,
                        url: 'https://dashboard-website.cdn.prismic.io/dashboard-website/cd4701b4-24ee-49d5-8295-8d817872d883_Klarian+Flow+For+Screen-04+-+Resized+for+web.svg',
                    }}
                    // video={{
                    //     url: mastheadBackgroundVideoUrl,
                    // }}
                    // videoEmbed={mastheadVideoEmbedId}
                />
                {components?.map(component => (
                    renderComponents(component, component?.slice_type)
                ))}
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($id: String!) {
        prismicHome(id: { eq: $id }) {
            data {
                body {
                ... on PrismicHomeBodyContentDiagram {
                    primary {
                        cd_button_text {
                            raw
                        }
                        cd_button_url {
                            link_type
                            type
                            uid
                            url
                        }
                        cd_content {
                            raw
                        }
                        cd_heading {
                            raw
                        }
                    }
                    slice_type
                }
                ... on PrismicHomeBodyContentLogoList {
                    items {
                        cl_logo_image {
                            alt
                            dimensions {
                                height
                                width
                            }
                            url
                        }
                        cl_logo_link_url {
                            link_type
                            type
                            uid
                            url
                        }
                    }
                    primary {
                        cl_button_text {
                            raw
                        }
                        cl_button_url {
                            link_type
                            type
                            uid
                            url
                        }
                        cl_content {
                            raw
                        }
                        cl_heading {
                            raw
                        }
                    }
                    slice_type
                }
                ... on PrismicHomeBodyContentSection {
                    primary {
                        cs_background_image {
                            alt
                            dimensions {
                                height
                                width
                            }
                            url
                        }
                        cs_button_text {
                            raw
                        }
                        cs_button_url {
                            link_type
                            type
                            uid
                            url
                        }
                        cs_content {
                            raw
                        }
                        cs_content_alignment
                        cs_heading {
                            raw
                        }
                    }
                    slice_type
                }
                ... on PrismicHomeBodyTimeline {
                    items {
                        fb_heading {
                            raw
                        }
                        fb_icon
                        fb_subheading {
                            raw
                        }
                    }
                    slice_type
                }
                ... on PrismicHomeBodyNewsletter {
                    primary {
                        nl_description {
                            raw
                        }
                        nl_form_description {
                            raw
                        }
                        nl_form_helper_text {
                            raw
                        }
                        nl_heading {
                            raw
                        }
                    }
                    slice_type
                }
                }
                ma_background_image {
                    alt
                    dimensions {
                        height
                        width
                    }
                    url
                }
                ma_background_video {
                    url
                }
                ma_description {
                    raw
                }
                ma_heading {
                    raw
                }
                ma_video_embed {
                    embed_url
                }
                page_meta_description {
                    raw
                }
                page_meta_title {
                    raw
                }
                page_social_share_image {
                    url
                }
            }
        }
    } 
`;

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default withPreview(HomePage);
