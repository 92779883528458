import React from 'react';
import PropTypes from 'prop-types';

import Button from 'atoms/button/Button';
import Content from 'atoms/content/Content';

import './ContentDiagram.scss';

const defaultProps = {
    className: '',
    content: [],
    heading: '',
    link: {},
    linkText: '',
};

const propTypes = {
    className: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.object),
    heading: PropTypes.string,
    link: PropTypes.object,
    linkText: PropTypes.string,
};

function ContentDiagram({
    className,
    content,
    heading,
    link,
    linkText,
}) {
    const linkUrl = link;

    return (
        <>
            <div className={`o-content-diagram ${className}`}>
                <div className="l-grid l-grid--container">
                    {heading && (
                        <div className="o-content-diagram__heading-container">
                            <h2 className="o-content-diagram__heading">{heading}</h2>
                        </div>
                    )}
                    <ul className="o-content-diagram__diagram-container">
                        <li className="o-content-diagram__diagram-item">
                            <span className="o-content-diagram__diagram-item-text-container">
                                <svg className="o-content-diagram__diagram-item-icon" viewBox="0 0 1080 1080"><path d="M276.1 276.2h35.3v582.2h-35.3z"/><path d="M169.9 51.3h247.8v247.8H169.9z" transform="rotate(45.001 293.772 175.216)"/><path d="M772 276.2h35.3v582.2H772z"/><path d="M665.7 51.3h247.8v247.8H665.7z" transform="rotate(45.001 789.642 175.21)"/><g><path d="M524 551h35.3v307.5H524z"/><path d="M417.8 306h247.8v247.8H417.8z" transform="rotate(45.001 541.71 429.919)"/></g><path d="M0 835.7h1080V1080H0z"/></svg>
                                <span className="o-content-diagram__diagram-item-text">Collect</span>
                            </span>
                        </li>
                        <li className="o-content-diagram__diagram-item">
                            <span className="o-content-diagram__diagram-item-text-container">
                                <svg className="o-content-diagram__diagram-item-icon" viewBox="0 0 1080 1080"><path d="M450.5 267.1h214.2v214.2H450.5z"  transform="rotate(45.001 557.576 374.14)"/><path d="M89.7 550.9h541.6v40.6H89.7z"  transform="rotate(134.999 360.494 571.225)"/><path d="M659.4 798.4h420.3v142.4H659.4z"  transform="rotate(45.001 869.582 869.561)"/><path d="M461.3 11.5c-248.4 0-449.8 201.4-449.8 449.8s201.4 449.8 449.8 449.8 449.8-201.4 449.8-449.8S709.7 11.5 461.3 11.5zm0 780.7c-182.8 0-331-148.2-331-331s148.2-331 331-331 331 148.2 331 331-148.2 331-331 331z" /></svg>
                                <span className="o-content-diagram__diagram-item-text">Analyse</span>
                            </span>
                        </li>
                        <li className="o-content-diagram__diagram-item">
                            <span className="o-content-diagram__diagram-item-text-container">
                                <svg className="o-content-diagram__diagram-item-icon" viewBox="0 0 1080 1080">
                                    <path d="M821.5 44.4h214.2v214.2H821.5z"  transform="rotate(45.001 928.566 151.43)"/>
                                    <path d="M52.2 813.7h214.2v214.2H52.2z"  transform="rotate(45.001 159.27 920.743)"/>
                                    <path d="M540 229.5c171.5 0 310.5 139 310.5 310.5S711.5 850.5 540 850.5 229.5 711.5 229.5 540s139-310.5 310.5-310.5z" />
                                    <path d="M78.5 73.7h155.6v155.6H78.5z"  transform="rotate(45.001 156.246 151.44)"/>
                                    <path d="M843 843h155.6v155.6H843z"  transform="rotate(45.001 920.756 920.734)"/>
                                    <path d="M-1.6 519.7h1083.2v40.6H-1.6z" transform="rotate(45.001 540.009 539.996)" />
                                    <path d="M-1.6 519.7h1083.2v40.6H-1.6z" transform="rotate(134.999 540.004 540.002)" />
                                </svg>
                                <span className="o-content-diagram__diagram-item-text">Interpret</span>
                            </span>
                        </li>
                        <li className="o-content-diagram__diagram-equals">=</li>
                        <li className="o-content-diagram__diagram-item">
                            <span className="o-content-diagram__diagram-item-text-container">
                                <svg className="o-content-diagram__diagram-item-icon" viewBox="0 0 667 667">
                                    <circle cx="333.5" cy="333.5" r="333.5" />
                                    <path d="M436.64 210.89h-62.41v266.78h62.41V210.89zM516.5 331.67l101.52-120.78H546.5L444.99 331.67l102.5 146H619l-102.5-146zM294.58 356.9 193.06 477.67h71.52L366.09 356.9l-102.5-146.01h-71.51l102.5 146.01z" fill="#fff" />
                                    <path d="M150.19 356.9 48.67 477.67h71.52L221.72 356.9 119.21 210.89H47.69l102.5 146.01z" fill="#fff" />
                                </svg>
                                <span className="o-content-diagram__diagram-item-text">Model</span>
                            </span>
                        </li>
                    </ul>
                    {content && (
                        <div className="o-content-diagram__content-container">
                            <Content className="o-content-diagram__content" content={content} />
                        </div>
                    )}
                    {linkText && linkUrl && (
                        <div className="o-content-diagram__button-container">
                            <Button className="a-button--secondary o-content-diagram__button" to={linkUrl}>{linkText}</Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

ContentDiagram.propTypes = propTypes;
ContentDiagram.defaultProps = defaultProps;

export default ContentDiagram;
