import React from 'react';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import Lottie from 'react-lottie';

import './FeatureBlockItem.scss';

const defaultProps = {
    animationData: {},
    className: '',
    heading: '',
    id: 0,
    onComplete: () => {},
    playAnimation: false,
    subheading: '',
};

const propTypes = {
    animationData: PropTypes.object,
    className: PropTypes.string,
    heading: PropTypes.string,
    id: PropTypes.number,
    onComplete: PropTypes.func,
    playAnimation: PropTypes.bool,
    subheading: PropTypes.string,
};

function FeatureBlockItem({
    animationData,
    className,
    heading,
    id,
    onComplete,
    playAnimation,
    subheading,
}) {
    const isPaused = playAnimation ? false : true;

    return (
        <>
            <div className={`m-feature-block-item ${className}`}>
                {animationData && (
                    <InView threshold={1} triggerOnce={true}>
                        {({ inView, ref, entry }) => {
                            const isVisible = entry && inView;
                            const isVisibleClass = (isVisible) ? 'is-visible' : '';

                            return (
                                <div className={`m-feature-block-item__icon-container ${isVisibleClass}`} ref={ref}>
                                    {isVisible && (
                                        <Lottie
                                            eventListeners={[
                                                {
                                                    eventName: 'complete',
                                                    callback: onComplete.bind(this, id),
                                                },
                                            ]}
                                            height={128}
                                            isPaused={isPaused}
                                            isStopped={isPaused}
                                            options={{
                                                loop: false,
                                                autoplay: false, 
                                                animationData: animationData,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid slice',
                                                },
                                            }}
                                            width={128}
                                        />
                                    )}
                                </div>
                            );
                        }}
                    </InView>
                )}
                {(heading || subheading) && (
                    <div className="m-feature-block-item__content-container">
                        {heading && (
                            <strong className="m-feature-block-item__heading">{heading}</strong>
                        )}
                        {subheading && (
                            <span className="m-feature-block-item__subheading">{subheading}</span>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}

FeatureBlockItem.propTypes = propTypes;
FeatureBlockItem.defaultProps = defaultProps;

export default FeatureBlockItem;